<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper">
                <a [routerLink]="'/dashboard'" class="w-100">
                    <img class="blur-up lazyloaded w-100" style="height: auto !important;" src="assets/images/logo.png" alt="">
                </a>
            </div>
        </div>
        <div class="mobile-sidebar col text-left">
            <div class="media-body text-left switch-sm">
                <label class="switch">
                    <a>
                        <i class="pi pi-bars" style="font-size: 2rem"
                        (click)="collapseSidebar()"
                        ></i>
                        
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus mb-0" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <div class="media align-items-center">
                            <span class="name pdd-right-5">{{user?.displayName}}</span>
                            <i class="ti-angle-down font-size-10"></i>

                        <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li>
                            <a [routerLink]="['/settings/profile/', user?.id]" title="Editar usuario">
                                Editar perfil
                            </a>
                        </li>
                        <li (click)="logout()">
                            <a>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
            </div>
        </div>
    </div>
</div>