import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './shared/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      { path: 'dashboard', loadChildren: () => import('src/app/components/dashboard/dashboard.module').then( (m) => m.DashboardModule) },
      { path: 'products', loadChildren: () => import('src/app/components/products/products.module').then( (m) => m.ProductsModule) },
      { path: 'catalogs', loadChildren: () => import('src/app/components/catalogs/catalogs.module').then( (m) => m.CatalogsModule) },
      { path: 'settings', loadChildren: () => import('src/app/components/settings/settings.module').then( (m) => m.SettingsModule) },
      { path: 'clients', loadChildren: () => import('src/app/components/users/users.module').then( (m) => m.UsersModule) },
      { path: 'users', loadChildren: () => import('src/app/components/users/users.module').then( (m) => m.UsersModule) },
      { path: 'sales', loadChildren: () => import('src/app/components/orders/orders.module').then( (m) => m.OrdersModule) },
      { path: 'sale-point', loadChildren: () => import('src/app/components/sale-point/sale-point.module').then( (m) => m.SalePointModule) },
      { path: 'profile', loadChildren: () => import('src/app/components/profile/profile.module').then( (m) => m.ProfileModule) },
    ]
  },
  {
    path: '',
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'auth/login', component: LoginComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
