import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule
  ],
  providers: [NavService, WINDOW_PROVIDERS],
})
export class SharedModule { }
