<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="bc"></p-toast>
<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container px-0 px-lg-5">
            <div class="row d-flex justify-content-center mx-0 mx-lg-5">
                <div class="col-12 col-lg-7 bg-white px-2 px-lg-3 justify-content-center shadow rounded-4 py-5">
                    <div class="col-12 d-flex justify-content-center pb-3">
                        <img src="../../../../assets/images/logo.png" style="width: 50%;" alt="">
                    </div>
                    <div class="col-12">
                        <div class="card-body" *ngIf="isLogin">
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form [formGroup]="authForm" (ngSubmit)="signIn()" novalidate="">
                                    <div class="p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                                        <span class="p-float-label">
                                            <input [formControlName]="'email'" class="w-100" type="text" id="inputtext" pInputText autocomplete="off" 
                                            [ngClass]="{'ng-invalid ng-dirty': error && (authForm.controls['email'].hasError('pattern') || authForm.controls['email'].hasError('required'))}"/>
                                            <label for="inputtext">Correo electrónico</label>
                                        </span>
                                    </div>
                                    <div class="p-field p-col-6 p-md-6 pt-3 mt-4">
                                        <span class="p-float-label w-100">
                                            <p-password
                                                [ngClass]="{'ng-invalid ng-dirty': error && authForm.controls['password'].hasError('required')}"
                                                [formControlName]="'password'"
                                                inputStyleClass="w-100"
                                                styleClass="w-100 p-password p-component p-inputwrapper p-input-icon-right"
                                                [feedback]="false"
                                                [toggleMask]="true"></p-password>
                                            <label for="inputtext">Contraseña</label>
                                        </span>
                                    </div>
                                    <div class="w-100 d-flex justify-content-end">
                                        <button [disabled]="submitted" pButton pRipple type="submit" class="p-button-raised mt-3 text-right">
                                            <span *ngIf="!submitted">Iniciar sesión</span>
                                            <i *ngIf="submitted" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="text-center mt-4">
                                <a class="text-danger pe-auto" style="cursor: pointer" (click)="isLogin = false; error = false;">¿Olvidaste tu contraseña? </a>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="!isLogin">
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form [formGroup]="authForm" (ngSubmit)="resetPassword()" novalidate="">
                                    <div class="p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                                        <span class="p-float-label">
                                            <input [formControlName]="'email'" class="w-100" type="text" id="inputtext" pInputText autocomplete="off" 
                                            [ngClass]="{'ng-invalid ng-dirty': error && (authForm.controls['email'].hasError('pattern') || authForm.controls['email'].hasError('required'))}"/>
                                            <label for="inputtext">Correo electrónico</label>
                                        </span>
                                    </div>
                                    <div class="w-100 d-flex justify-content-end">
                                        <button [disabled]="submitted" pButton pRipple type="submit" class="p-button-raised text-center mt-3 w-100 text-right">
                                            <span class="w-100 text-center" *ngIf="!submitted">Validar correo</span>
                                            <i *ngIf="submitted" class="w-100 text-center pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="text-center mt-4">
                                <a class="text-danger pe-auto" style="cursor: pointer" (click)="isLogin = true; error = false;">Iniciar sesión </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>