import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/shared/service/api-services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ MessageService ]
})
export class LoginComponent implements OnInit {

  public authForm: FormGroup;
  public submitted: boolean = false;
  public isLogin: boolean = true;
  public error: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private router: Router
  ) { 
    this.authForm = this._fb.group({
      email: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]],
      password: ['', Validators.required]
    });
  }

  public resetPassword(){
    this.submitted = true;
    if( this.authControls['email'].value === '' ){
      if(this.authForm.invalid){ 
        this.showMessage('bc', 'warn', 'Advertencia', 'Debes proporcionarnos tu correo para validarlo', 'pi-info-circle');
        this.submitted = false;
        this.error = true;
        return;
      }  
    }
    const body = { email : this.authControls["email"].value}
    this.authService.resetPassword(body).subscribe( data => {
      this.showMessage('bc', data.Type, data.Type === 'success' ? 'Exito' : 'Error', data.Message, 'pi-check-circle');
        this.submitted = false;
        if(data.Type !== 'error')
          this.isLogin = true;
    })

  }

  public signIn(): void {
    this.submitted = true;
    if(this.authForm.invalid){ 
      this.showMessage('bc', 'warn', 'Advertencia', 'Debes llenar todos los campos', 'pi-info-circle');
      this.error = true;
      this.submitted = false;
      return;
    }
    const login = this.buildUser();
    this.authService.signIn(login).subscribe( 
      (response: any) => {
        localStorage.setItem('currentUser', JSON.stringify(response));
        this.submitted = false;
        this.error = false;
        this.router.navigate(['/dashboard']);
      },
      (error: any) => {
        this.showMessage('bc', 'error', 'Error', error.error.Message, 'pi-info-circle');
        this.submitted = false;
        this.error = false;
      }
    );
  }

  buildUser(){
    return {
      email: this.authControls['email'].value,
      password: this.authControls['password'].value
    }
  }

  showMessage(key: string, type: string, title: string, content: string, icon: string ) {
    this.messageService.add({
      key: key, 
      severity:type, 
      summary: title, 
      detail: content, 
      icon: icon
    });
  }

  public get authControls(){
    return this.authForm.controls;
  }

  ngOnInit(): void {
  }

}
