import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { getHeaders } from '../../utils/headers';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../../interfaces/interfaces-list';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
 
  constructor(private http: HttpClient, private router: Router) {}

  public get currentUserValue(): any {
    return 1;
  }

  getUserById(id: number) {
    const headers = getHeaders();
    return this.http.get<any>(`${BASE_URL}/user/${id}`, {headers});
  }

  signIn(body: { email: string, password: string }): Observable<any> {
    return this.http.post<any>(`${BASE_URL}/auth/admin-login`, body);
  }
  
  resetPassword(body: { email: string }) {
    return this.http.post<any>(`${BASE_URL}/auth/forgot-passwordAdmin`, body);
  }

  updateUser(id: number, form: UserInfo) {
    const headers = getHeaders();
    return this.http.put<any>(`${BASE_URL}/user/${id}`, form, {headers});
  }
  
  updatePassword(form: any) {
    const headers = getHeaders();
    return this.http.post<any>(`${BASE_URL}/auth/change-password`, form, {headers});
  }

}
